<template>
  <div class="scrollable-form">
    <h2 class="item-heading">{{ $t('form.Form') }}</h2>

    <!-- Category Selector Component -->
    <CategorySelector :errors="errors" @updateCategory="handleCategoryUpdate" />

    <!-- Conditional Rendering Based on Layout Type -->
    <template v-if="isImageLayout">
      <!-- File Upload Component -->
      <FileUpload :errors="errors" @updateFile="handleFileUpdate" @updateError="handleErrorUpdate" />
    </template>


    <!-- Item Description Component -->
    <ItemDescription :initialDescription="store.pois[0].contentBlock.itemDescription" @updateDescription="handleDescriptionUpdate" />

    <button @click.prevent="submitAll" class="btn-submit">{{ $t('form.submit') }}</button>

    <div v-if="submitting" class="spinner-modal">
      <div class="spinner-box">
        <div class="spinner"></div>
        <p>Submitting...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import { s3Upload } from "@/utils/fileUpload";
import { useRouter } from "vue-router";
import api from "@/endpoints/api";
import VueCookies from "vue-cookies";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import CategorySelector from "@/components/CategorySelector.vue";
import FileUpload from "@/components/FileUpload.vue";
import ItemDescription from "@/components/ItemDescription.vue";
import { saveOfflineSubmission, syncOfflineSubmissions } from "@/utils/offlineStorage"; 
import { db } from "@/db";

export default {
  components: {
    CategorySelector,
    FileUpload,
    ItemDescription,
  },
  setup() {
    const store = useDataStore();
    const router = useRouter();
    return { store, router };
  },
  data() {
    return {
      submitting: false,
      errors: {
        selectedCategory: false,
        selectedFile: false,
      },
      isImageLayout: true, // Default layout type
      layoutCard: null,    
      fieldKey: null,  
    };
  },
  async mounted() {
    // Fetch the layout configs
    await this.fetchLayouts();

    // Sync offline submissions when the app is back online
    window.addEventListener("online", async () => {
      await syncOfflineSubmissions();
    });
  },
  methods: {
    async fetchLayouts() {
  try {
    const response = await api.get("/api/ui_support/configs/", {
      params: { org_domain_name: this.store.payload.org_domain_name },
    });
    const layouts = response.data.configs.layouts;

    if (layouts.image) {
      const imageKey = Object.keys(layouts.image.layout)[0]; // "i1"
      const imageMedia = layouts.image.layout[imageKey][1].Media;

      this.isImageLayout = true;
      this.layoutCard = layouts.image.pk; 
      this.fieldKey = imageKey; 
      this.mediaType = imageMedia.PK; 
    } else if (layouts.text) {
      const textKey = Object.keys(layouts.text.layout)[0]; // "rt1"
      const textMedia = layouts.text.layout[textKey][1].Media;

      this.isImageLayout = false;
      this.layoutCard = layouts.text.pk; 
      this.fieldKey = textKey; 
      this.mediaType = textMedia.PK;
    }
  } catch (error) {
    console.error("Error fetching layouts:", error);
    alert("Failed to fetch layout configurations.");
  }
},
    handleCategoryUpdate(newCategory) {
      this.store.selectedCategory = newCategory;
      this.errors.selectedCategory = !newCategory;
    },
    handleFileUpdate(file) {
      this.store.selectedFile = file;
      this.errors.selectedFile = !file;
    },
    handleErrorUpdate(error) {
      this.errors = { ...this.errors, ...error };
    },
    handleDescriptionUpdate(description) {
      this.store.pois[0].contentBlock.itemDescription = description;
    },
    validateForm() {
      this.errors.selectedCategory = !this.store.selectedCategory;
      this.errors.selectedFile = this.isImageLayout && !this.store.selectedFile;

      return !this.errors.selectedCategory && (!this.isImageLayout || !this.errors.selectedFile);
    },
    async uploadFile() {
      const file = this.store.selectedFile;
      if (file && file.type.startsWith("image/")) {
        try {
          const options = {
            maxSizeMB: process.env.VUE_APP_UPLOAD_MAX_FILESIZE_MB,
            maxWidthOrHeight: process.env.VUE_APP_UPLOAD_MAX_WIDTH_AND_HEIGHT,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const uploadID = await s3Upload(compressedFile);
          this.store.setUploadID(uploadID);
        } catch (err) {
          console.error("Failed to upload file: ", err);
          alert("Failed to upload file. Check console for details.");
        }
      } else if (this.isImageLayout) {
        alert("Please select a file to upload.");
      }
    },
    async submitAll() {
      if (!this.validateForm()) {
        alert("Please fill out all required fields.");
        return;
      }

      this.submitting = true;

      if (this.isImageLayout && this.store.selectedFile && !this.store.uploadID) {
        await this.uploadFile();
        console.log("File uploaded with ID: ", this.store.uploadID);
      } else {
        console.log("No file uploaded, using existing upload ID: ", this.store.uploadID);
      }

      const description = this.store.pois[0].contentBlock.itemDescription;
      const finalDescription = description;

      const newPOI = {
  type: "Feature",
  geometry: {
    type: "Point",
    coordinates: [
      this.store.pois[0].longitude,
      this.store.pois[0].latitude,
    ],
  },
  properties: {
    map_content: this.store.pois[0].map_content,
    name: uuidv4(),
    sub_categories: this.store.pois[0].sub_categories.length > 0
      ? this.store.pois[0].sub_categories.map(Number)
      : [this.store.selectedCategory],
    content_block: [
      {
        language: "en",
        layout_card: this.layoutCard, 
        content_array: [
          {
            reactive: 0,
            media_type: this.mediaType, 
            media_file: this.isImageLayout ? this.store.uploadID : null,
            field_key: this.fieldKey, 
            caption: finalDescription || "not given",
          },
        ],
      },
    ],
  },
};



      const payload = {
        org_domain_name: this.store.payload.org_domain_name,
        org_key: this.store.payload.org_key,
        user_supplied_name: this.store.userSuppliedName.trim() || "sam",
        id_field: VueCookies.get("id_field") || 0,
        pass_key: VueCookies.get("pass_key") || "string",
        user_supplied_email: this.store.userSuppliedEmail.trim() || "user@example.com",
        map_json: {
          is_public: true,
          languages: ["en"],
          pois: [newPOI],
        },
        post_ready: true,
      };

      if (!navigator.onLine) {
        // Save data offline with the file
        await saveOfflineSubmission(payload, this.store.selectedFile);
        alert("You are offline. Your submission will be sent once you're back online.");
      } else {
        // Otherwise, submit immediately
        await this.submitPayload(payload);
      }

      this.submitting = false;
    },


async submitPayload(payload) {
  try {
    const map_pk = VueCookies.get("map_pk");
    let response;

    if (map_pk) {
      response = await api.patch(`/api/anon-map-update/${map_pk}/`, payload);
    } else {
      response = await api.post("/api/anon-map-create/", payload);
      console.log("New map created successfully: ", response.data);

      // Store map_pk, pass_key, and id_field in cookies for future updates
      const pass_key = response?.data?.pass_key;
      if (pass_key) {
        VueCookies.set("pass_key", pass_key, "1y");
        console.log("pass_key set: ", pass_key);
      }
      const id_field = response?.data?.id_field;
      if (id_field) {
        VueCookies.set("id_field", id_field, "1y");
        console.log("id_field set: ", id_field);
      }
      const new_map_pk = response?.data?.map_pk;
      if (new_map_pk) {
        VueCookies.set("map_pk", new_map_pk, "1y");
        console.log("map_pk set: ", new_map_pk);
      }
    }
    
    const pois = response?.data?.pois;
    console.log("pois", pois);
    if (pois && pois.length > 0) {
      console.log("POIs returned from server:", pois);
      const poi = pois[pois.length - 1];
      try {
        await db.pois.add({
          poi_id: poi.id,
          file_key: this.store.fileKey,
          name: poi.name,
        });
        console.log(`POI added to local DB: ${poi.name}`);
      } catch (error) {
        console.error("Failed to add POI to local database:", error);
      }
    }

    this.$emit("formSubmitted");

    await syncOfflineSubmissions(); 
  } catch (error) {
    console.error("Failed to submit data:", error);
    alert("Data will be submitted when you are back online.");
    await saveOfflineSubmission(payload); 
  }
    },
  },
};
</script>


<style scoped>
.scrollable-form {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
}

.item-heading {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}


.btn-submit {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #3cb317;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-box {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #333;
  animation: spin 1s linear infinite;
}

/* Mobile Responsive Styles */
@media (max-width: 600px) {
  .scrollable-form {
    max-height: 50vh;
    padding-right: 5px;
  }

  .form-group input,
  .form-group select,
  .btn-submit {
    font-size: 12px;
    padding: 8px;
  }

  .item-heading {
    font-size: 16px;
  }
  input::placeholder,
  .form-group textarea {
    font-size: 10.3px;
    color: #666;
  }
}
</style>
