import axios from 'axios';
import { preferences as defaultPreferences } from '@/config.js';

// Environment variables
const API_URL = process.env.VUE_APP_STROLLOPIA_API_URL;
const ORG_DOMAIN = process.env.VUE_APP_ORG_DOMAIN;

// Deep merge function for nested keys
const deepMerge = (target, source) => {
  for (const key in source) {
    if (
      typeof source[key] === "object" &&
      source[key] !== null &&
      !(source[key] instanceof Array)
    ) {
      if (!target[key]) {
        target[key] = {};
      }
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

export const fetchPreferences = async () => {
  try {
    console.log("Fetching preferences from API...");
    const url = `${API_URL}/api/ui_support/configs/?org_domain_name=${ORG_DOMAIN}`;
    console.log(`Request URL: ${url}`);

    const response = await axios.get(url);

    // Extract `configs.app_preferences` from the API response
    const fetchedPreferences = response.data.configs?.app_preferences;
    if (!fetchedPreferences) {
      console.warn("API response does not include `app_preferences`. Falling back to defaults.");
      return defaultPreferences;
    }

    console.log("Fetched Preferences:", fetchedPreferences);

    // Merge with defaults this part is not needed as the backend does the job but its there as an extra mesuare
    const mergedPreferences = deepMerge({ ...defaultPreferences }, fetchedPreferences);
    console.log("Merged Preferences:", mergedPreferences);

    return mergedPreferences;
  } catch (error) {
    console.error("Failed to fetch preferences from API:", error);
    return defaultPreferences;
  }
};
