import axios from 'axios';
import defaultTranslations from '@/locales/en.json';

// Environment variables
const API_URL = process.env.VUE_APP_STROLLOPIA_API_URL;
const ORG_DOMAIN = process.env.VUE_APP_ORG_DOMAIN;

// Deep merge function for nested keys this part is not needed any more its just there as an extra mesuare
const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (
      typeof source[key] === 'object' &&
      source[key] !== null &&
      !(source[key] instanceof Array)
    ) {
      if (!target[key]) {
        target[key] = {};
      }
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

export const fetchTranslationsForLocale = async (locale) => {
  try {
    const url = `${API_URL}/api/ui_support/translations/?org_domain_name=${ORG_DOMAIN}`;
    console.log(`Fetching translations from: ${url}`);

    const response = await axios.get(url);

    // Extract translations for the specific locale
    const apiTranslations = response.data.translations?.[locale.toUpperCase()];
    if (!apiTranslations) {
      console.warn(`Translations for locale "${locale}" not found in API response.`);
      return defaultTranslations;
    }

    console.log("API Translations for locale:", apiTranslations);

    // Merge with defaults this part is not needed as the backend does the job but its there as an extra mesuare
    return deepMerge({ ...defaultTranslations }, apiTranslations);
  } catch (error) {
    console.warn(`Failed to fetch translations for locale "${locale}", using defaults.`);
    console.error(error);
    return defaultTranslations;
  }
};