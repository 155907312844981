import { reactive } from "vue";

const data = reactive({
  mapID: null, 
  uploadMaxFilesize: process.env.VUE_APP_UPLOAD_MAX_FILESIZE_MB,
});

export default {
  methods: {
    getData(key) {
      // Check if the key exists in global $preferences
      if (this.$preferences && this.$preferences.appInfo && key in this.$preferences.appInfo) {
        return this.$preferences.appInfo[key];
      }
      if (this.$preferences && this.$preferences.externalLinks && key in this.$preferences.externalLinks) {
        return this.$preferences.externalLinks[key];
      }
      if (this.$preferences && this.$preferences.splashPage && key in this.$preferences.splashPage) {
        return this.$preferences.splashPage[key];
      }

      // Check if the key exists in the local `data` store
      if (key in data) {
        return data[key];
      }

      console.warn(`Key "${key}" is missing in both preferences and local data.`);
      return undefined;
    },
    setData(key, value) {
      console.log(`Setting data for key "${key}":`, value);
      data[key] = value;
    },
  },
};
