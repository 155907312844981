import { db } from "@/db";
import api from "@/endpoints/api";
import VueCookies from "vue-cookies";
import { s3Upload } from "@/utils/fileUpload";

export const saveOfflineSubmission = async (payload, file) => {
  try {
    let base64File = null;
    let fileName = null;
    let fileType = null;

    if (file) {
      base64File = await convertFileToBase64(file);
      fileName = file.name;
      fileType = file.type;
    }

    const map_pk = VueCookies.get("map_pk") || null;
    await db.submissions.add({
      id: Date.now(),
      payload,
      map_pk,
      base64File,
      fileName,
      fileType,
    });
    console.log("Saved offline submission with file metadata:", payload, fileName, fileType);
  } catch (error) {
    console.error("Error saving offline submission:", error);
  }
};


// Helper function to convert file to Base64
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const syncOfflineSubmissions = async () => {
  const submissions = await db.submissions.toArray();

  for (const submission of submissions) {
    try {
      const { payload, map_pk, base64File, fileName, fileType } = submission;
      
      // Initialize the fileUploadID as null
      let fileUploadID = null;

      if (base64File) {
        const fileBlob = convertBase64ToBlob(base64File, fileType);
        const fileToUpload = new File([fileBlob], fileName || "offline-upload", {
          type: fileType || "application/octet-stream",
        });

        // Wait for the file to upload and retrieve the upload ID
        fileUploadID = await s3Upload(fileToUpload);
        console.log("File uploaded with ID:", fileUploadID);

        // Update the payload with the new upload ID
        const contentBlock = payload.map_json.pois[0].properties.content_block[0];
        contentBlock.content_array[0].media_file = fileUploadID;
      }

      if (fileUploadID || !base64File) {
        let response;

        if (map_pk) {
          response = await api.patch(`/api/anon-map-update/${map_pk}/`, payload);
        } else {
          response = await api.post("/api/anon-map-create/", payload);

          const new_map_pk = response?.data?.map_pk;
          if (new_map_pk) {
            VueCookies.set("map_pk", new_map_pk, "1y");
          }
        }

        // Clean up the synced submission
        await db.submissions.delete(submission.id);
        console.log("Submission synced and removed from local DB");
      } else {
        console.warn("File upload failed, skipping submission sync for:", submission.id);
      }
    } catch (error) {
      console.error("Error syncing offline submission:", error);
    }
  }
};


// Helper function to convert Base64 to Blob
const convertBase64ToBlob = (base64, mimeType) => {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeType });
};
