<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { fetchTranslationsForLocale } from "@/utils/fetchTranslations";

export default {
  name: "App",

  watch: {
    "$i18n.locale": async function (newLocale) {
      // Fetch translations on locale change
      const translations = await fetchTranslationsForLocale(newLocale);
      this.$i18n.setLocaleMessage(newLocale, translations);
    },
  },

  async created() {
    // Load translations for the current locale
    const locale = this.$i18n.locale || "en";
    const translations = await fetchTranslationsForLocale(locale);
    this.$i18n.setLocaleMessage(locale, translations);

    console.log(`Loaded translations for locale "${locale}":`, translations);
    console.log("Loaded preferences:", this.$preferences); 
  },
};
</script>

<style>
@import "@/assets/style.css";
</style>
