import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router.js";
import { createPinia } from "pinia";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/iconsets/mdi";

import { createI18n } from "vue-i18n";
import en from "./locales/en.json";

import { fetchPreferences } from "@/utils/fetchPreferences";
import { preferences as defaultPreferences } from "./config"; 
//deep merge this part is not needed anymore as the backend handle it but its there as an extra mesuare 
const deepMerge = (target, source) => {
  for (const key in source) {
    if (
      typeof source[key] === "object" &&
      source[key] !== null &&
      !(source[key] instanceof Array)
    ) {
      if (!target[key]) {
        target[key] = {};
      }
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

const app = createApp(App);
const pinia = createPinia();

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
  },
});

const vuetify = createVuetify({
  components,
  directives,
  aliases,
  sets: {
    mdi,
  },
});

const initializeApp = async () => {
  const reactivePreferences = reactive({ ...defaultPreferences });

  try {
    const fetchedPreferences = await fetchPreferences();
    deepMerge(reactivePreferences, fetchedPreferences);
  } catch (error) {
    console.warn("Failed to fetch preferences, using defaults.");
  }

  // Set preferences globally and come back to make sure how their reactivity works 
  app.config.globalProperties.$preferences = reactivePreferences;

  app.use(router);
  app.use(pinia);
  app.use(vuetify);
  app.use(i18n);

  app.mount("#app");

  console.log("Final Reactive Preferences:", reactivePreferences);
};

initializeApp();

